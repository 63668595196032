<template>
  <div id="st-container" class="st-container">
    <SearchMultiSelect
      :selectedOptions="selected"
      :sourceOptions="nightOptions"
      :isCheckbox="2"
      :placeholder="$t(`search-tab.choose-${type}`)"
      @onChangeSelect="changedMultiSelection"
    />
    <div class="input-group-append">
      <span class="input-group-text">
        <img :src="`${speedSizeDomain}/assets/img/calendar.png`" alt="calendar" style="width:25px;" width="25" height="25"/>
      </span>
    </div>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'PricePlanMultipleSelect',
  mixins: [imageUrlMixin],
  components: {
    SearchMultiSelect: () => import('@/components/atoms/SearchMultiSelect'),
  },
  props: {
    type: {
      type: String,
      default: 'night',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    nightOptions() {
      const { options } = this;
      if (!options) return [];
      return options.map((item, inx) => ({
        id: inx,
        value: item,
        label: this.type === 'filter-months' ? Object.values(this.$t('monthName'))[item] : item,
      }));
    },
  },
  watch: {
    options() {
      this.selected = [];
    },
    nightOptions() {
      if (this.type === 'filter-night' || this.type === 'filter-months') { this.selected = [...this.nightOptions]; }
    },
  },
  mounted() {
    if (this.type === 'filter-night' || this.type === 'filter-months') { this.selected = [...this.nightOptions]; }
  },
  methods: {
    changedMultiSelection(selected) {
      const { type } = this;
      if (!this.selected) return;
      this.$emit('changeDestination', { type, selected, _uid: this._uid });
    },
    resetData() {
    },
  },
};
</script>
<style scoped>
  .st-container {
    overflow: visible;
  }
  .input-group-append {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 48px;
  }
</style>
<style>
input[type='search'] {
  margin: 0;
  padding: 0;
  border: 0;
}
.filter-tab .st-container .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
}
.vs__selected {
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.style-chooser .vs__selected-options {
  display: contents;
}

.filter-tab form button.btn {
  height: 45px;
}
.checkbox-label {
  display: block;
}

.checkbox {
  position: absolute;
  left: 1vw;
}
</style>
